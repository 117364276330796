var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('DetailOrderItemInputStockAsset',{ref:"dropdownStockAsset",staticClass:"col-12",attrs:{"label":"สินทรัพย์","placeholder":"สินทรัพย์...","select":"id","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.$v.formData.docConfigId.$dirty && _vm.$v.formData.docConfigId.$error}
      ]},model:{value:(_vm.formData.docConfigId),callback:function ($$v) {_vm.$set(_vm.formData, "docConfigId", $$v)},expression:"formData.docConfigId"}}),_c('DetailOrderItemInputPrice',{ref:"price",staticClass:"col-6",attrs:{"label":"ค่าเสื่อม","placeholder":"ค่าเสื่อม","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.$v.formData.price.$dirty && !_vm.$v.formData.price.required},
        {text: 'จำนวนมากกว่า 0', value: _vm.$v.formData.price.$dirty && !_vm.$v.formData.price.minValue}
      ]},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}}),_c('DetailOrderItemInputPrice',{ref:"excessPrice",staticClass:"col-6",attrs:{"label":"ค่าเสื่อมบวกกลับ","placeholder":"ค่าเสื่อมบวกกลับ","docType":_vm.docType,"templateType":_vm.templateType,"validations":[
        {text: 'required!', value: _vm.$v.formData.excessPrice.$dirty && !_vm.$v.formData.excessPrice.required},
        {text: 'จำนวนมากกว่าหรือเท่ากับ 0', value: _vm.$v.formData.excessPrice.$dirty && !_vm.$v.formData.excessPrice.minValue}
      ]},model:{value:(_vm.formData.excessPrice),callback:function ($$v) {_vm.$set(_vm.formData, "excessPrice", $$v)},expression:"formData.excessPrice"}})],1),_c('button',{ref:"submit",staticClass:"btn btn-success mb-3",on:{"click":_vm.createOrder}},[_vm._v(" เพิ่ม ")])])}
var staticRenderFns = []

export { render, staticRenderFns }