<template>
  <tr v-if="!row.parentId || selectedRow === row.parentId">
    <td>
      <fa
        v-if="canDestroy && (selectedRow === row.id || selectedRow === row.parentId)"
        icon="trash"
        class="text-danger pointer"
        @dblclick="destroyOrder(row.id, row.parentId)">
      </fa>
      <span v-if="!row.parentId">
        <span
          class="pointer"
          :class="{'text-warning': selected === row.id}"
          @dblclick.stop="emitInput(row)"
          @click.stop="selectOrder(row)">
          {{row.id}}
        </span>
      </span>
    </td>
    <td>
      <span v-for="ledger in row.ledgers" :key="ledger.id">
        <small :class="{
          'text-success': ledger.approvedAt,
          'text-warning': !ledger.approvedAt
          }"
          class="text-nowrap">
          {{ledger.account.code}}: {{ledger.account.name}}
          <span v-if="ledger.approvedAt" class="text-secondary">({{ledger.approvedAt | date}})</span>
        </small>
        <br>
      </span>
      <span style="white-space: pre-line;">{{ row.name }}</span>
      <span
        v-for="stock in row.stocks"
        :key="stock.id"
        class="text-primary text-nowrap">
        <br>
        <small>
          <em>{{ stock.inventory.code }} ({{stock.inventory.name}})</em>
        </small>
      </span>
    </td>
    <td>
      <span v-for="ledger in row.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{ row.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import { DESTROY_ORDER } from './graph'

export default {
  name: 'SubOrderItem',
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    selected: {
      type: Number,
      required: false
    },
    canDestroy: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {

    }
  },
  computed: {
    selectedRow () {
      return this.value.find(v => v === this.row.id || v === this.row.parentId)
    }
  },
  methods: {
    selectOrder (row) {
      if (row.type === 'item') {
        if (this.selected === row.id) {
          this.$emit('update:selected', null)
        } else {
          this.$emit('update:selected', row.id)
        }
      }
    },
    getChildren (type, children) {
      return children
      .filter(v => v.docId !== this.docId && v.doc.type === type)
      .reduce((t,v) => {
        const found = t.find(x => x.id === v.doc.id)
        if (!found) t.push(v.doc)
        return t
      }, [])
    },
    getRemaining (row) {
      return row.children.reduce((t,child) => {
        return child.stocks.reduce((sub,v) => sub += parseFloat(v.qty), t)
      }, parseFloat(row.qty))
    },
    emitInput (row) {
      if (row.parentId) return
      const found = this.value.indexOf(row.id)
      const arr = [...this.value]
      if (found === -1) {
        arr.push(row.id)
      } else {
        arr.splice(found, 1)
      }
      this.$emit('input', arr)
    },
    destroyOrder (id) {
      this.$apollo.mutate({
        mutation: DESTROY_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          orderId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitOrderUpdated () {
      this.$emit('updated', null)
    },
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
