<template>
  <sgv-input-currency
    ref="item"
    :value="value"
    @input="emitInput"
    :validations="validations"
    v-bind="$attrs">
  </sgv-input-currency>
</template>

<script>
export default {
  name: 'DetailOrderItemInputPrice',
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    value: {
      type: [Object, String, Number],
      required: false
    },
    validations: {
      type: Array,
      required: false,
      default() {
        return [
          {value: false, text: ''}
        ]
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    emitInput (value) {
      this.$emit('input', value)
    },
    setFocus () {
      this.$refs.item.setFocus()
    }
  }
}
</script>

<style lang="css">
</style>
