<template>
  <div class="">
    <div class="form-row">
      <DetailOrderItemInputStockAsset
        label="สินทรัพย์"
        ref="dropdownStockAsset"
        placeholder="สินทรัพย์..."
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.docConfigId"
        :validations="[
          {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
        ]">
      </DetailOrderItemInputStockAsset>

      <DetailOrderItemInputPrice
        label="ค่าเสื่อม"
        ref="price"
        class="col-6"
        placeholder="ค่าเสื่อม"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.price"
        :validations="[
          {text: 'required!', value: $v.formData.price.$dirty && !$v.formData.price.required},
          {text: 'จำนวนมากกว่า 0', value: $v.formData.price.$dirty && !$v.formData.price.minValue}
        ]">
      </DetailOrderItemInputPrice>

      <DetailOrderItemInputPrice
        label="ค่าเสื่อมบวกกลับ"
        ref="excessPrice"
        class="col-6"
        placeholder="ค่าเสื่อมบวกกลับ"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.excessPrice"
        :validations="[
          {text: 'required!', value: $v.formData.excessPrice.$dirty && !$v.formData.excessPrice.required},
          {text: 'จำนวนมากกว่าหรือเท่ากับ 0', value: $v.formData.excessPrice.$dirty && !$v.formData.excessPrice.minValue}
        ]">
      </DetailOrderItemInputPrice>
    </div>

    <button
      ref="submit"
      class="btn btn-success mb-3"
      @click="createOrder">
      เพิ่ม
    </button>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderItemInputStockAsset from './DetailOrderItemInputStockAsset.vue'
import DetailOrderItemInputPrice from './DetailOrderItemInputPrice.vue'
import {
  CREATE_ORDER_ITEM,
  DETAIL_ORDER_ITEM_DEDUCTION_RATE
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      formType: 'item',
      formData: {
        docConfigId: null,
        price: 0,
        excessPrice: 0
      }
    }
  },
  validations: {
    formData: {
      docConfigId: {required},
      price: {
        required,
        minValue: (value) => value > 0
      },
      excessPrice: {
        required,
        minValue: (value) => value >= 0
      }
    }
  },
  methods: {
    createOrder () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_ITEM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.formData
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownStockAsset.setFocus()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.price = 0
      this.formData.excessPrice = 0
      this.$v.$reset()
    },
    refetch () {
      this.$apollo.query({
        query: DETAIL_ORDER_ITEM_DEDUCTION_RATE(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.formData.docConfigId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        const {amountRate, excessRate} = res.data.deductionRate
        this.formData.price = amountRate || 0
        this.formData.excessPrice = excessRate || 0
      })
    },
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.price = 0
          this.formData.excessPrice = 0
          this.$v.$reset()
        } else {
          this.refetch()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderItemInputStockAsset,
    DetailOrderItemInputPrice
  }
}
</script>

<style lang="css" scoped>
</style>
